var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        class: [
          "container container--full-screen",
          { mobileScreen: _vm.isMobile },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "page-manager" },
          [
            _vm.currentStep.step === 1
              ? _c("ManagerTable")
              : _c("TimeSlotDetail", {
                  attrs: { timeslot: _vm.currentStep.data },
                }),
            _c("DialogAddTimeslot"),
            _vm.terminalSlug !== "step"
              ? _c("DialogAddTimeslotHand")
              : _c("StepDialogAddTimeslotHand"),
            _c("StepDialogEditTimeslot"),
            _c("DialogTimeslotViewing"),
            _c("DialogTimeslotWrong"),
            _c("DialogEditTimeslot"),
            _c("DialogDeleteTimeslot"),
            _c("DialogAdminDeleteTimeslot"),
            _c("DialogForceArrival"),
            _c("DialogChangeTableColumn"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }