var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "time-slot-detail" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "time-slot-detail__header" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "warning", icon: "el-icon-back" },
              on: { click: _vm.handleGoBack },
            },
            [_vm._v(" Вернуться назад ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.getLoading,
              expression: "getLoading",
            },
          ],
          staticClass: "time-slot-detail__body",
        },
        [
          _c(
            "el-table",
            {
              key: _vm.logs.length,
              ref: "tableTimeslotDetail",
              staticClass: "table-timeslot-detail",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.logs, stripe: "" },
            },
            [
              _vm._l(_vm.timeSlotDetailTableFields, function (item) {
                return [
                  _c("el-table-column", {
                    key: item.key,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: item.width ? item.width : "",
                      align: item.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getPrepareVal(scope, item.prop)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }