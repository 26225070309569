var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "change-column",
      attrs: { name: _vm.name, width: "800px" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Изменение колонок таблицы")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("el-transfer", {
        attrs: {
          titles: ["Доступно к отображению", "Отображается"],
          data: _vm.data,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "div",
        { staticClass: "change-column__btns" },
        [
          _c(
            "iq-button",
            {
              attrs: { size: "small", color: "gray" },
              on: { onClick: _vm.handleClose },
            },
            [_vm._v(" Отменить ")]
          ),
          _c(
            "iq-button",
            { attrs: { size: "small" }, on: { onClick: _vm.handleConfirm } },
            [_vm._v(" Подтвердить ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }