<template>
  <div class="time-slot-detail">
    <div class="container">
      <div class="time-slot-detail__header">
        <el-button type="warning" icon="el-icon-back" @click="handleGoBack">
          Вернуться назад
        </el-button>
      </div>

      <div v-loading="getLoading" class="time-slot-detail__body">
        <el-table
          ref="tableTimeslotDetail"
          :key="logs.length"
          class="table-timeslot-detail"
          :data="logs"
          style="width: 100%"
          stripe
        >
          <template v-for="item in timeSlotDetailTableFields">
            <el-table-column
              :key="item.key"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : ''"
              :align="item.align"
            >
              <template slot-scope="scope">
                {{ getPrepareVal(scope, item.prop) }}
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_LOGS,
  GET_LOGS_FROM_STATE,
  GET_LOGS_IS_LOADING,
  GET_TIMESLOTS_CURRENT_STEP,
  SET_TIMESLOT_CURRENT_STEP,
} from '@/views/manager/store/actions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  timeSlotDetailTableFields,
  timeslotDetailEvents,
  timeslotDetailStatuses,
  timeslotDetailTypes,
} from '@/views/manager/components/time-slot-detail/data/time-slot-detail'

export default {
  name: 'TimeSlotDetail',
  props: {
    timeslot: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeSlotDetailTableFields,
    }
  },
  computed: {
    ...mapGetters({
      logs: GET_LOGS_FROM_STATE,
      getLoading: GET_LOGS_IS_LOADING,
      currentStep: GET_TIMESLOTS_CURRENT_STEP,
    }),
  },
  created() {
    this.getActions(this.currentStep.data.id || '')
  },
  methods: {
    ...mapMutations({ setCurrentStep: SET_TIMESLOT_CURRENT_STEP }),
    ...mapActions({ getActions: GET_LOGS }),
    handleGoBack() {
      this.setCurrentStep({ step: 1, data: {} })
    },
    getPrepareVal({ row }, prop) {
      if (prop === 'type') {
        return timeslotDetailTypes[row[prop]] || '-'
      }

      if (prop === 'status') {
        return timeslotDetailStatuses[row[prop]] || '-'
      }

      if (prop === 'event') {
        return timeslotDetailEvents[row[prop]] || '-'
      }

      return row[prop]
    },
  },
}
</script>

<style lang="sass">
.time-slot-detail
  padding: 20px 0

  &__header
    margin-bottom: 20px
</style>
