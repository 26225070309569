export const timeSlotDetailTableFields = [
  {
    prop: 'id',
    label: 'Id',
    key: 1,
    width: '70',
    sortable: true,
  },
  {
    prop: 'receiver',
    label: 'Абонент',
    key: 2,
    width: '130',
  },
  {
    prop: 'type',
    label: 'Тип',
    key: 3,
    width: '70',
  },
  {
    prop: 'status',
    label: 'Статус',
    key: 4,
    width: '100',
  },
  {
    prop: 'event',
    label: 'Событие',
    key: 5,
    width: '200',
  },
  {
    prop: 'created_at',
    label: 'Время',
    key: 6,
    width: '180',
  },
  {
    prop: 'message',
    label: 'Сообщение',
    key: 7,
  },
]

export const timeslotDetailTypes = {
  call: 'Звонок',
  sms: 'СМС',
}

export const timeslotDetailStatuses = {
  success: 'Успех',
  fail: 'Неудача',
}

export const timeslotDetailEvents = {
  timeslotEdited: 'Таймслот изменен',
  timeslotDeleted: 'Таймслот удален',
  timeslotMovedToBuffer: 'Перемещен в буфер',
  timeslotMovedToTerminal: 'Перемещен в терминал',
  timeslotParkingWarning: 'Возвращен на парковку',
  userCreated: 'Таймслот создан пользователелм',
}
